<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-form
          :inline="true"
          size="small"
          :model="searchForm"
          @submit.native.prevent
      >
        <el-form-item label="顾客姓名/编号">
          <el-input
              v-model="searchForm.customer"
              placeholder="请输入顾客姓名/编号"
              clearable
              @keyup.enter.native="handleSearch"
              @clear="handleSearch"
          />
        </el-form-item>
        <el-form-item label="归属门店">
          <el-select
              v-model="searchForm.entityId"
              clearable
              filterable
              placeholder="请选择门店"
              :default-first-option="true"
              @change="handleSearch"
          >
            <el-option
                v-for="item in storeEntityList"
                :key="item.ID"
                :label="item.EntityName"
                :value="item.ID"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="建档日期">
          <el-date-picker v-model="searchForm.createdOn" unlink-panels type="daterange" range-separator="至"
                          value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期"
                          @change="handleSearch"/>
        </el-form-item>
        <el-form-item label="购买属性">
          <el-input v-model="searchForm.buyProperty" placeholder="请输入购买属性" clearable
                    @keyup.enter.native="handleSearch" @clear="handleSearch"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-button v-if="purchaseExport" type="primary" size="small" :loading="downloadLoading" @click="downloadExcel">
          导出
        </el-button>
      </el-form>
    </div>
    <el-table size="small" :data="dataList" max-height="660" border>
      <el-table-column prop="customerName" label="顾客姓名" fixed="left"/>
      <el-table-column prop="customerCode" label="会员编号" fixed="left" min-width="100"/>
      <el-table-column prop="entityName" label="归属门店" fixed="left" min-width="100"/>
      <el-table-column prop="createdOn" label="建档时间" fixed="left" min-width="120"/>
      <el-table-column prop="saleBillCount" label="总订单数" fixed="left" min-width="80"/>
      <el-table-column label="首次购卡" min-width="200">
        <template slot-scope="scope">
          <div>购卡日期: {{ getProperty(scope.row.firstSaleBill, "billDate") }}</div>
          <div>订单号: {{ getProperty(scope.row.firstSaleBill, "billId") }}</div>
          <div>卡项名称: {{ getProperty(scope.row.firstSaleBill, "cardNames") }}</div>
          <div>支付金额: {{ getProperty(scope.row.firstSaleBill, "payAmount") }}</div>
          <div>卡扣金额: {{ getProperty(scope.row.firstSaleBill, "cardDeductionAmount") }}</div>
          <div>实付金额: {{ getProperty(scope.row.firstSaleBill, "realPayAmount") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="firstBuyProperty" label="首次购买属性"/>
      <el-table-column label="二次（第二个订单）" min-width="200">
        <template slot-scope="scope">
          <div>购卡日期: {{ getProperty(scope.row.secondSaleBill, "billDate") }}</div>
          <div>订单号: {{ getProperty(scope.row.secondSaleBill, "billId") }}</div>
          <div>卡项名称: {{ getProperty(scope.row.secondSaleBill, "cardNames") }}</div>
          <div>支付金额: {{ getProperty(scope.row.secondSaleBill, "payAmount") }}</div>
          <div>卡扣金额: {{ getProperty(scope.row.secondSaleBill, "cardDeductionAmount") }}</div>
          <div>实付金额: {{ getProperty(scope.row.secondSaleBill, "realPayAmount") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="secondBuyProperty" label="二次购买属性"/>
      <el-table-column prop="betweenFirstPurchaseDays" label="距离上次购买间隔天数"/>
      <el-table-column label="三次（第三个订单）" min-width="200">
        <template slot-scope="scope">
          <div>购卡日期: {{ getProperty(scope.row.thirdSaleBill, "billDate") }}</div>
          <div>订单号: {{ getProperty(scope.row.thirdSaleBill, "billId") }}</div>
          <div>卡项名称: {{ getProperty(scope.row.thirdSaleBill, "cardNames") }}</div>
          <div>支付金额: {{ getProperty(scope.row.thirdSaleBill, "payAmount") }}</div>
          <div>卡扣金额: {{ getProperty(scope.row.thirdSaleBill, "cardDeductionAmount") }}</div>
          <div>实付金额: {{ getProperty(scope.row.thirdSaleBill, "realPayAmount") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="thirdBuyProperty" label="三次购买属性"/>
      <el-table-column prop="betweenSecondPurchaseDays" label="距离上次购买间隔天数"/>
      <el-table-column label="四次（第四个订单）" min-width="200">
        <template slot-scope="scope">
          <div>购卡日期: {{ getProperty(scope.row.fourthSaleBill, "billDate") }}</div>
          <div>订单号: {{ getProperty(scope.row.fourthSaleBill, "billId") }}</div>
          <div>卡项名称: {{ getProperty(scope.row.fourthSaleBill, "cardNames") }}</div>
          <div>支付金额: {{ getProperty(scope.row.fourthSaleBill, "payAmount") }}</div>
          <div>卡扣金额: {{ getProperty(scope.row.fourthSaleBill, "cardDeductionAmount") }}</div>
          <div>实付金额: {{ getProperty(scope.row.fourthSaleBill, "realPayAmount") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="fourthBuyProperty" label="四次购买属性"/>
      <el-table-column prop="betweenThirdPurchaseDays" label="距离上次购买间隔天数"/>
      <el-table-column label="五次（第五个订单）" min-width="200">
        <template slot-scope="scope">
          <div>购卡日期: {{ getProperty(scope.row.fifthSaleBill, "billDate") }}</div>
          <div>订单号: {{ getProperty(scope.row.fifthSaleBill, "billId") }}</div>
          <div>卡项名称: {{ getProperty(scope.row.fifthSaleBill, "cardNames") }}</div>
          <div>支付金额: {{ getProperty(scope.row.fifthSaleBill, "payAmount") }}</div>
          <div>卡扣金额: {{ getProperty(scope.row.fifthSaleBill, "cardDeductionAmount") }}</div>
          <div>实付金额: {{ getProperty(scope.row.fifthSaleBill, "realPayAmount") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="fifthBuyProperty" label="五次购买属性"/>
      <el-table-column prop="betweenFourthPurchaseDays" label="距离上次购买间隔天数"/>
      <el-table-column label="十次（第十个订单）" min-width="200">
        <template slot-scope="scope">
          <div>购卡日期: {{ getProperty(scope.row.tenthBill, "billDate") }}</div>
          <div>订单号: {{ getProperty(scope.row.tenthBill, "billId") }}</div>
          <div>卡项名称: {{ getProperty(scope.row.tenthBill, "cardNames") }}</div>
          <div>支付金额: {{ getProperty(scope.row.tenthBill, "payAmount") }}</div>
          <div>卡扣金额: {{ getProperty(scope.row.tenthBill, "cardDeductionAmount") }}</div>
          <div>实付金额: {{ getProperty(scope.row.tenthBill, "realPayAmount") }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="tenthBuyProperty" label="十次购买属性"/>
      <el-table-column prop="betweenNinthPurchaseDays" label="距离上次购买间隔天数"/>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination v-if="page.total > 0" background :current-page.sync="page.pageIndex" :page-size="page.pageSize"
                     :total="page.total"
                     layout="total, prev, pager, next" @current-change="initDataList"/>
    </div>
  </div>
</template>
<script>
import EntityAPI from "@/api/Report/Common/entity";
import * as API from '@/api/index'
import permission from "@/components/js/permission";

export default {
  name: "purchaseUpgrade",
  data() {
    return {
      loading: false,
      downloadLoading: false,
      searchForm: {
        customer: "",
        entityId: "",
        createdOn: [],
        buyProperty: ""
      },
      page: {
        pageIndex: 1,
        pageSize: 10,
        total: 0
      },
      storeEntityList: [],
      dataList: [],
      purchaseExport: false,

    };
  },
  mounted() {
    this.initExportBtn();
    this.initEntityList();
    this.handleSearch();
  },
  computed: {
    baseUrl() {
      return 'api/customerPurchaseUpgrade/';
    }
  },
  methods: {
    initExportBtn() {
      this.purchaseExport = permission.permission(
          this.$route.meta.Permission,
          "Report-Customer-PurchaseUpgrade-Export"
      );
    },
    getProperty(obj, property) {
      return obj && property && obj[property];
    },
    initDataList() {
      this.loading = true;
      const params = {
        customer: this.searchForm.customer,
        belongEntity: this.searchForm.entityId,
        createdOnStart: (this.searchForm.createdOn ?? [])[0],
        createdOnEnd: (this.searchForm.createdOn ?? [])[1],
        buyProperty: this.searchForm.buyProperty,
        pageNum: this.page.pageIndex
      };
      API.GET(this.baseUrl + "list", params)
          .then(res => {
            if (res.StateCode == 200) {
              this.dataList = res.List;
              this.page.total = res.Total;
            } else {
              this.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(() => {
            this.loading = false;
          })
    },
    handleSearch() {
      this.page.pageIndex = 1;
      this.initDataList();
    },
    downloadExcel() {
      this.downloadLoading = true;
      const params = {
        customer: this.searchForm.customer,
        belongEntity: this.searchForm.entityId,
        createdOnStart: (this.searchForm.createdOn ?? [])[0],
        createdOnEnd: (this.searchForm.createdOn ?? [])[1],
        buyProperty: this.searchForm.buyProperty
      };
      API.exportExcel(this.baseUrl + "export/excel", params)
          .then(res => {
            this.$message.success({
              message: "正在导出",
              duration: "4000",
            });
            const link = document.createElement("a");
            let blob = new Blob([res], {type: "application/octet-stream"});
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.download = "顾客购买升单报表.xlsx"; //下载的文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .finally(() => {
            this.downloadLoading = false;
          })
    },
    initEntityList() {
      EntityAPI.getStoreEntityList().then((res) => {
        if (res.StateCode == 200) {
          this.storeEntityList = res.Data;
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
